import { serializeRichTextToMarkdown } from "@brm/schema-helpers/rich-text/serialize.js"
import type { Conversation, Message, PickableEntityFilter } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { isEmpty } from "@brm/util/type-guard.js"
import { Box, Button, HStack, Icon, Stack, StackDivider, Text, useToast } from "@chakra-ui/react"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { nanoid } from "@reduxjs/toolkit"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { type Descendant } from "slate"
import braimLogo from "../../../assets/braim.svg"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import BetsyDisplayName from "../../components/ContextMenu/BetsyDisplayName.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import { CopyIcon, SendIcon, ShareIcon } from "../../components/icons/icons.js"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor.js"
import { DEFAULT_PICKABLE_ENTITIES, trimRichTextWhiteSpace } from "../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { log } from "../../util/logger.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { ChatMessage, type ChatMessageAuthor } from "./ChatMessage.js"

const defaultMessages: Message[] = [
  { content: "Hi, I will gladly answer your questions about tools, vendors, and more!", role: "assistant" },
]

const defaultSuggestedPrompts: string[] = ["What is BRM?", "Who owns BRM?", "Purchase BRM"]

export default function BetsyChat({
  conversation,
  startNewConversation,
  onError,
  onSuccess,
  value,
}: {
  conversation: Partial<Omit<Conversation, "id">> & { id: string }
  startNewConversation: () => void
  onError?: () => void
  onSuccess?: () => void
  value?: Descendant[]
}) {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { id: conversationId } = conversation
  const newBetsyEnabled = useFeatureFlagEnabled("new-betsy")
  const actualState = useState("waiting")
  const [messages, setMessages] = useState<Message[] | null>(conversation?.messages ?? defaultMessages)
  const [status, setStatus] = newBetsyEnabled ? actualState : ["", () => {}]
  const [editorKey, setEditorKey] = useState(0)
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)
  const bottomRef = useRef<HTMLDivElement>(null)
  const responseKey = useRef<string | null>()
  const inputRef = useRef<HTMLDivElement>(null)

  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>(defaultSuggestedPrompts)
  const [draft, setDraft] = useState<Descendant[] | undefined>(undefined)
  // Reset state when conversation Id changes
  useEffect(() => {
    responseKey.current = null
    setMessages(conversation.messages ?? defaultMessages)
    setDraft(undefined)
    setSuggestedPrompts(defaultSuggestedPrompts)
    setIsLoadingAnswer(false)
    inputRef.current?.focus()
  }, [conversationId, conversation.messages])

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(
    () => ({
      entities: DEFAULT_PICKABLE_ENTITIES,
    }),
    []
  )

  useEffect(() => {
    if (!isLoadingAnswer && suggestedPrompts.length > 0) {
      // Scroll to bottom when suggested prompts are updated
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    if (window.innerHeight - bottomRef.current!.getBoundingClientRect().y > 0) {
      // Pin to bottom if the user is already scrolled to the bottom
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    // Scroll to bottom on new agent replies
    if (messages?.[messages.length - 1]?.content === "") {
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [messages, suggestedPrompts, isLoadingAnswer])

  const isReadOnly = conversation?.user_id && whoami?.id !== conversation.user_id

  const userAuthor: ChatMessageAuthor = useMemo(
    () =>
      conversation.user_id && conversation?.user_id !== whoami?.id
        ? {
            name:
              conversation?.user?.first_name ||
              (conversation?.user && displayPersonName(conversation.user, intl)) ||
              "User",
            image: getPublicImageGcsUrl(conversation?.user?.profile_image?.gcs_file_name),
            role: "user" as const,
          }
        : {
            name: whoami?.first_name || (whoami && displayPersonName(whoami, intl)) || "User",
            image: getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name),
            role: "user" as const,
          },
    [conversation, whoami, intl]
  )

  const fetchAnswer = useCallback(
    async (draft: Descendant[], messages: Message[] | null) => {
      setIsLoadingAnswer(true)
      const myResponseKey = nanoid(4)
      responseKey.current = myResponseKey
      await fetchEventSource(`${import.meta.env.VITE_API_BASE_URL}/betsy/v1/ask`, {
        method: "POST",
        credentials: "include",
        openWhenHidden: true,
        body: JSON.stringify({
          query: draft,
          messages,
          conversation_id: conversationId,
          log_message: true,
          new_betsy: newBetsyEnabled,
        }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: "text/event-stream",
          ["Content-Type"]: "application/json",
        },
        async onopen(res) {
          if (res.ok && res.status === 200) {
            if (myResponseKey !== responseKey.current) {
              return
            }
            onError?.()
          } else if (res.status === 401) {
            navigate("/login")
          } else {
            log.error("braim api failure", res, { draft, messages })
            if (myResponseKey !== responseKey.current) {
              return
            }
            toast({ description: "Something went wrong", status: "error" })
            throw new Error("braim api failure")
          }
        },
        onmessage(event) {
          if (myResponseKey !== responseKey.current) {
            return
          }
          if (event.event === "data") {
            setStatus("")
            const data = JSON.parse(event.data)
            const text = data?.text
            const suggestedPrompts = data?.suggestedPrompts
            if (!isEmpty(suggestedPrompts)) {
              setSuggestedPrompts(suggestedPrompts)
            }
            if (!isEmpty(text)) {
              setDraft(undefined)
              setMessages((c) => {
                if (c === null) {
                  return null
                }
                const currentMessage: Message = c.at(-1)!
                const ret = c.slice(0, -1)
                ret.push({ ...currentMessage, content: currentMessage.content + text })

                return ret
              })
            }
          } else if (event.event === "end") {
            onSuccess?.()
            setDraft(undefined)
            setStatus("waiting")
            setIsLoadingAnswer(false)
          } else if (event.event === "status") {
            const data = JSON.parse(event.data)
            setStatus(data.status)
            log.info("braim api status", data.status)
          }
        },
        onclose() {
          if (myResponseKey !== responseKey.current) {
            return
          }
          onSuccess?.()
          setIsLoadingAnswer(false)
        },
        onerror(e) {
          log.error("braim api failure", e, { draft, messages })
          if (myResponseKey !== responseKey.current) {
            return
          }
          toast({ description: "Something went wrong", status: "error" })
          onError?.()
          setIsLoadingAnswer(false)
          throw e
        },
      })
    },
    [conversationId, navigate, newBetsyEnabled, onError, onSuccess, setStatus, toast]
  )

  const submit = useCallback(async () => {
    const trimmedBody = draft && trimRichTextWhiteSpace(draft)
    if (trimmedBody) {
      const serializedQuery = serializeRichTextToMarkdown(trimmedBody)
      setMessages((c) =>
        c === null
          ? null
          : [
              ...c,
              { content: serializedQuery, role: "user", rich_text: trimmedBody },
              { content: "", role: "assistant" },
            ]
      )
      setDraft(undefined)
      setEditorKey((key) => key + 1)
      await fetchAnswer(trimmedBody, messages)
    }
  }, [draft, messages, setMessages, setDraft, setEditorKey, fetchAnswer])

  return (
    <>
      <Box overflowY="auto" paddingTop={1} paddingBottom="40">
        <Stack
          maxW="prose"
          mx="auto"
          paddingX={{ base: "1", md: "0" }}
          divider={
            <Box marginLeft="14!">
              <StackDivider />
            </Box>
          }
          spacing="5"
        >
          {messages?.map((m, i) => (
            <ChatMessage
              richText={(m.rich_text as Descendant[]) ?? undefined}
              status={i === messages.length - 1 && status !== "waiting" ? status : undefined}
              after={
                m !== defaultMessages[0] &&
                i === messages.length - 1 && (
                  <HStack py={2}>
                    <IconButtonWithTooltip
                      placement="bottom"
                      label={intl.formatMessage({
                        id: "message.copy.tooltip",
                        description: "Tooltip for the copy button",
                        defaultMessage: "Copy",
                      })}
                      variant="ghost"
                      onClick={async () => {
                        await navigator.clipboard.writeText(m.content)
                        toast({
                          description: intl.formatMessage({
                            id: "conversation.copy.success",
                            description: "Message displayed when the user successfully copies the message",
                            defaultMessage: "Copied to clipboard",
                          }),
                          status: "success",
                        })
                      }}
                      icon={<Icon as={CopyIcon} />}
                      size="sm"
                    />
                    <IconButtonWithTooltip
                      variant="ghost"
                      onClick={async () => {
                        const url = new URL(`braim/${conversationId}`, window.location.origin)
                        await navigator.clipboard.writeText(url.toString())
                        toast({
                          description: intl.formatMessage({
                            id: "conversation.share.success",
                            description: "Message displayed when the user successfully copies the conversation link",
                            defaultMessage: "Link copied to clipboard",
                          }),
                          status: "success",
                        })
                      }}
                      placement="bottom"
                      label={intl.formatMessage({
                        id: "conversation.share.tooltip",
                        description: "Tooltip for the share button",
                        defaultMessage:
                          "Share conversation. Anyone in your organization with the link will be able to view this conversation",
                      })}
                      icon={<Icon as={ShareIcon} />}
                      size="sm"
                    />
                  </HStack>
                )
              }
              isLoading={i === messages.length - 1 && isLoadingAnswer}
              key={i}
              author={
                m.role === "user"
                  ? userAuthor
                  : {
                      name: "Braim",
                      renderedName: (
                        <Text display="inline" fontWeight="medium">
                          <BetsyDisplayName />
                        </Text>
                      ),
                      image: braimLogo,
                      role: m.role,
                    }
              }
              content={m.content}
            />
          ))}
        </Stack>
        <Box height="1px" ref={bottomRef} />
      </Box>

      <Box
        pos="absolute"
        bottom="0"
        insetX="0"
        bgGradient="linear(to-t, white 80%, rgba(0,0,0,0))"
        paddingY="4"
        marginX="4"
      >
        <Stack maxW="prose" mx="auto">
          <HStack flexWrap="wrap" gap={2}>
            {!isLoadingAnswer &&
              suggestedPrompts.map((prompt, i) => (
                <SuggestedPromptBadge
                  key={i}
                  prompt={prompt}
                  onClick={() => {
                    const draft = [
                      {
                        type: "paragraph",
                        children: [
                          {
                            text: prompt,
                          },
                        ],
                      },
                    ] satisfies Descendant[]
                    setDraft(draft)
                    setMessages((c) =>
                      c === null ? null : [...c, { content: prompt, role: "user" }, { content: "", role: "assistant" }]
                    )
                    return fetchAnswer(draft, messages)
                  }}
                />
              ))}
          </HStack>
          <Box as="form" pos="relative" my={3}>
            <RichTextEditor
              initialValue={value}
              containerProps={{
                onKeyDown: async (e) => {
                  if (e.key === "Enter" && (e.metaKey || e.ctrlKey) && !isLoadingAnswer && !isReadOnly) {
                    await submit()
                  } else if (e.key === "k" && e.metaKey && e.shiftKey) {
                    startNewConversation()
                  }
                },
              }}
              key={editorKey}
              onChange={(e) => {
                setDraft(e)
              }}
              sendButton={
                <Button
                  isDisabled={isReadOnly || isLoadingAnswer || !draft}
                  isLoading={isLoadingAnswer}
                  size="sm"
                  variant="text"
                  colorScheme="gray"
                  aria-keyshortcuts="Enter"
                  onClick={submit}
                >
                  <Icon as={SendIcon} />
                </Button>
              }
              pickableEntityFilters={pickableEntityFilters}
              ref={inputRef}
            />
          </Box>
        </Stack>
      </Box>
    </>
  )
}
